import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LandingPageService } from 'libs/shared/services/landing-page/landing-page.service';

@Component({
  selector: 'pc-ui-form-wishlist',
  templateUrl: './form-wishlist.component.html',
  styleUrls: ['./form-wishlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormWishlistComponent implements OnInit {
  form = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    type: ['', Validators.required]
    // companyName: ['']
  });

  constructor(
    private fb: FormBuilder,
    private landingPageService: LandingPageService
  ) {}

  ngOnInit() {}

  submit() {
    console.log(this.form.value);
    this.landingPageService
      .saveToWaitingList(this.form.value)
      .subscribe(data => {
        console.log(data);
      });
  }
}
