import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: ' ',
    loadChildren: () =>
      import('libs/landing-page/src/lib/landing-page.module').then(
        m => m.LandingPageModule
      ),
    data: {
      moduleName: 'LandingPage'
    }
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
