import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pc-ui-section-story',
  templateUrl: './section-story.component.html',
  styleUrls: ['./section-story.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionStoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
