import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  NavBarComponent,
  HeroComponent,
  SectionComponent,
  FormWishlistComponent,
  SectionStoryComponent
} from './components';

const COMPONENTS = [
  NavBarComponent,
  HeroComponent,
  SectionComponent,
  FormWishlistComponent,
  SectionStoryComponent
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatButtonToggleModule],
  declarations: [COMPONENTS],
  exports: [COMPONENTS]
})
export class UiModule {}
