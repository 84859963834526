import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  constructor(private httpClient: HttpClient) {}

  saveToWaitingList(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    });
    console.log(data);

    const url = 'https://pipecrowd-261900.appspot.com/wishlist/register_beta/';
    // 'http://pipe-env.bpfkycmdgg.us-east-1.elasticbeanstalk.com/submit';
    // const url = 'http://127.0.0.1:5000/submit';

    return this.httpClient.post(url, data, { headers: headers });
  }
}
